@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
@import "fonts";
@import "variables";
@import "bootstrap.scss";
@import "colors";
@import "mixins";
@import "cmixins";
@import "resets";
.test_abc {
  font-size: 50px;

  @include responsive-down(md) {
    font-size: 90px;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
.my-modal {
  .modal-content {
    border-radius: 20px;
    background-image: url("/assets/nft-week.png");
    margin-top: 50px;
  }
  .modal-header {
    border: none !important;
    div {
      text-align: center;
      width: 100%;
      margin-top: -48px;
      
    }
  }
}
.my-modal-music {
  .modal-content {
    border-radius: 20px;
    background-image: url("/assets/musician-week.png");
    margin-top: 50px;
   
  }
  .modal-header {
    border: none !important;
    div {
      text-align: center;
      width: 100%;
      margin-top: -48px;
      @include responsive-down(sm) {
        margin-top: -44px;
      }
    }
  }
}
.nav-tabs .nav-link.active{
  background: none !important;
}

.modal-open .modal {
  padding-right: 0px !important;
}

.ResponsiveTable {
  overflow-x:auto;
  width: 100%;
}

.CustomizeTable {
  border: 0;
  width: 100%;

  tr:nth-child(even) {
    background: #F9FAFB
  }

  th {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #E5E7EB;
    padding: 20px 18px;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #C2C2C2;
    white-space: nowrap;
    vertical-align: middle;
  }
  td {
    vertical-align: middle;
    border: 0;
    padding: 20px 18px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #636363;
    white-space: nowrap;
  }
}

.table_sorting {
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}

.success_status {
  color: #27AE60;
}

.error_status {
  color: #E74C3C;
}

.font-700 {
  font-weight: 700;
}

// start create button tooltip css

.gems_tooltip {
  background: #7557BB;
  bottom: 100%;
  color: #fff;
  display: block;
  transform: translate(-50%, 0%);
  left: 50%;
  right: 0;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  width: 341px;
  filter: drop-shadow(0px 4px 8px rgba(41, 41, 42, 0.2)) drop-shadow(0px 4px 8px rgba(41, 41, 42, 0.07));
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
      transition: all .25s ease-out;

      
  @media screen and (max-width: 1199px) {
    left: 0;
  }

  @media screen and (max-width: 370px) {
    width: 300px;
  }

  @media screen and (max-width: 345px) {
    width: 270px;
  }
}

.gems_tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

.gems_tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #7557BB 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;

  @media screen and (max-width: 1199px) {
    left: 19%;
  }
}


.tooltip_section, .tooltip_fav_section, .tooltip_user_section {
  position: relative;
  display: inline-block;
}

.tooltip_section:hover .gems_tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
     -o-transform: translateY(0px);
      transform: translateY(0px);
  transform: translate(-50%, 0%);

  @media screen and (max-width: 1199px) {
    transform: translate(0%, 0%);
  }
}
  
.tooltip_section .gems_tooltip {
  display: none;
}

.tooltip_section:hover .gems_tooltip {
  display: block;
  z-index: 1000;
}

// end create button tooltip css

// start favorite tooltip css

.gems_fav_tooltip {
  background: #7557BB;
  bottom: 100%;
  color: #fff;
  display: block;
  transform: translate(-50%, 0%);
  left: 50%;
  right: 0;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  width: 341px;
  filter: drop-shadow(0px 4px 8px rgba(41, 41, 42, 0.2)) drop-shadow(0px 4px 8px rgba(41, 41, 42, 0.07));
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
      transition: all .25s ease-out;

      
  @media screen and (max-width: 1300px) {
    left: 0;
    width: 320px;
  }

  @media screen and (max-width: 370px) {
    width: 300px;
  }

  @media screen and (max-width: 345px) {
    width: 270px;
  }
}

.gems_fav_tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

.gems_fav_tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #7557BB 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;

  @media screen and (max-width: 1199px) {
    margin-left: 29px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    right: 14%;
    left: auto;
  }

  @media screen and (min-width: 1301px) and (max-width: 1439px) {
    right: 22%;
    left: auto;
  }

  @media screen and (min-width: 1440px) and (max-width: 1480px) {
    left: 56%;
  }
}

.tooltip_fav_section:hover .gems_fav_tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  transform: translate(-49%, 0%);

  @media screen and (min-width: 1440px) and (max-width: 1480px) {
    transform: translate(-55%, 0%);
  }

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    transform: translate(-75%, 0%);
  }

  @media screen and (max-width: 1199px) {
    transform: translate(-55%, 0%);
  }
}
  
.tooltip_fav_section .gems_fav_tooltip {
  display: none;
}

.tooltip_fav_section:hover .gems_fav_tooltip {
  display: block;
}

// end favorite tooltip css

// start userprofile tooltip css

.gems_user_tooltip {
  background: #7557BB;
  bottom: 100%;
  color: #fff;
  display: block;
  transform: translate(-50%, 0%);
  left: 50%;
  right: 0;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  width: 280px;
  filter: drop-shadow(0px 4px 8px rgba(41, 41, 42, 0.2)) drop-shadow(0px 4px 8px rgba(41, 41, 42, 0.07));
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
      transition: all .25s ease-out;

      
  @media screen and (max-width: 1199px) {
    left: 0;
  }

  @media screen and (max-width: 370px) {
    width: 280px;
  }

  @media screen and (max-width: 345px) {
    width: 270px;
  }
}

.gems_user_tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

.gems_user_tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #7557BB 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 14%;
  margin-left: -15px;
  position: absolute;
  width: 0;

  @media screen and (max-width: 1199px) {
    left: 11%;
  }
}

.tooltip_user_section:hover .gems_user_tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
     -o-transform: translateY(0px);
      transform: translateY(0px);
  transform: translate(-12%, 0%);

  @media screen and (max-width: 1199px) {
    transform: translate(0%, 0%);
  }
}
  
.tooltip_user_section .gems_user_tooltip {
  display: none;
}

.tooltip_user_section:hover .gems_user_tooltip {
  display: block;
}

// end userprofile tooltip css

// start purchase button tooltip css

.purchase_tooltip {
  background: #7557BB;
  bottom: 100%;
  color: #fff;
  display: block;
  transform: translate(-50%, 0%);
  left: 50%;
  right: 0;
  margin-bottom: 10px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  width: 296px;
  filter: drop-shadow(0px 4px 8px rgba(41, 41, 42, 0.2)) drop-shadow(0px 4px 8px rgba(41, 41, 42, 0.07));
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
      transition: all .25s ease-out;

      
  @media screen and (max-width: 1199px) {
    left: 0;
    width: 270px;
  }

  @media screen and (max-width: 1023px) {
    width: 280px;
  }
}

.purchase_tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

.purchase_tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #7557BB 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  right: 15%;
  margin-right: 0px;
  position: absolute;
  width: 0;

  @media screen and (max-width: 1023px) {
    right: 12%;
  }
}


.tooltip_section, .tooltip_fav_section, .tooltip_user_section {
  position: relative;
  display: inline-block;
}

.tooltip_section:hover .purchase_tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
     -o-transform: translateY(0px);
      transform: translateY(0px);
  transform: translate(-82%, 0%);

  @media screen and (max-width: 1199px) {
    transform: translate(-63%, 0%);
  }

  @media screen and (max-width: 1023px) {
    transform: translate(-68%, 0%);
  }
}
  
.tooltip_section .purchase_tooltip {
  display: none;
}

.tooltip_section:hover .purchase_tooltip {
  display: block;
  z-index: 1000;
}

// end purchase button tooltip css

.gems_icon_img {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-left: 12px;
  margin-top: 2px;
  position: relative;
}

.tab-content {
  width: 100%;
}

.chart_hovers {
  display: flex;
  flex-direction: column;
  padding: 8px 30px;
  text-align: center;
  align-items: center;
}

.chart_headings_separate {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.apexcharts-tooltip.apexcharts-theme-light {
  background: #FFFFFF !important;
  border: 1px solid #CCCCCC !important;
  box-shadow: 0px 4px 8px rgba(41, 41, 42, 0.18) !important;
  border-radius: 10px !important;
  font-family: "Jost", sans-serif !important;
}

.apexcharts-text tspan {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #666666;
  font-family: "Jost", sans-serif !important;

  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.chart_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #666666;
  font-family: "Jost", sans-serif !important;
}

.chart_headings {
  font-family: "Jost", sans-serif !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #000000;
}

.graphs_heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #1A1A1A;
  margin-bottom: 0;

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
}

.graph_gems_small_img {
  width: 50px;
  height: 50px;
  display: inline-flex;
  position: relative;
  
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
}

.gems_earning_text {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #969BA0;
  opacity: 0.5;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.apexcharts-tooltip.apexcharts-theme-dark {
  color: #666666 !important;
  background: #ffffff !important;
}

.apexcharts-tooltip-text {
  font-size: 14px !important;
  line-height: 20px;
  font-family: "Jost", sans-serif !important;
}

.tooltip_gems_small_img {
  width: 12px;
  height: 12px;
  display: flex;
  position: relative;

  svg {
    margin: 0 !important;
  }
}

@-webkit-keyframes fadeOutRight {
	0% {
		opacity: 1
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
}

@keyframes fadeOutRight {
	0% {
		opacity: 1
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
}

.section_spacing {
  padding-top: 120px;

  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
}

.section_spacing_winner {
  padding-top: 70px;

  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
}


.thankyougemstitles_purchase {
  font-weight: 600;
  font-size: 21px;
  line-height: 32px;
  color: #FF7900;
  margin-bottom: 0px;
  margin-left: 92px;

  @media screen and (max-width: 1023px) {
    font-size: 22px;
    line-height: 35px;
  }
}

.thankyougems_title_separates_purchase {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.cong_gems_small_img_purchase {
  width: 28px;
  height: 28px;
  display: flex;
  position: relative;

  @media screen and (max-width: 1023px) {
    width: 18px;
    height: 18px;
  }
}
