* {
    padding: 0;
    margin: 0;
}
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

html,
body {
    height: 100%;
}

body {
    font-family: $primary-font;
    font-weight: 400;
    // background-color: #16151a;
    -webkit-font-smoothing: antialiased;
    background: #ffffff;
}

button {
    padding: 0;
    border: none;
    background-color: transparent;
    transition: 0.5s ease;
    transition-property: color, background-color, border-color, box-shadow;
    cursor: pointer;
}
button:focus {
    outline: none;
}
a {
    transition: 0.5s ease;
    transition-property: color, background-color, border-color, box-shadow;
}
a:hover,
a:active,
a:focus {
    outline: none;
    text-decoration: none;
}
input,
textarea,
select {
    padding: 0;
    margin: 0;
    border-radius: 0;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // -ms-appearance: none;
    // appearance: none;
    box-shadow: none;
    transition: 0.5s ease;
    transition-property: color, border-color, box-shadow;
}
input:focus,
textarea:focus,
select:focus {
    outline: none;
}
select::-ms-expand {
    display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
::-moz-selection {
    background: $tertiary_color;
    color: inherit;
    text-shadow: none;
}
::selection {
    background: $tertiary_color;
    color: inherit;
    text-shadow: none;
}
::-webkit-input-placeholder {
    color: #bdbdbd;
    opacity: 1;
}
::-moz-placeholder {
    color: #bdbdbd;
    opacity: 1;
}
:-moz-placeholder {
    color: #bdbdbd;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #bdbdbd;
    opacity: 1;
}
:focus {
    outline: -webkit-focus-ring-color auto 0px;
}
body::-webkit-scrollbar {
    width: 5px;
}
body::-webkit-scrollbar-track {
    background: #222227;
}
body::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    outline: 1px solid #222227;
}
.tab_content {
    width: 100%;
}
.tab_content > .tab_pane {
    display: none;
}
.tab_content > .active {
    display: block;
}
.fade {
    transition: opacity 0.4s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}
.fade:not(.show) {
    opacity: 0;
}
.collapse:not(.show) {
    display: none;
}
.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}
:focus_visible {
    outline: 0px dotted;
}
.row__grid {
    margin-right: -10px;
    margin-left: -10px;
}
.row__grid .col-6,
.row__grid .col-12 {
    padding-left: 10px;
    padding-right: 10px;
}
@media screen and (min-width: 768px) {
    // .row__grid {
    //   margin-right: -15px;
    //   margin-left: -15px;
    // }
    .row__grid .col-6,
    .row__grid .col-12 {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.row__sidebar {
    margin-right: -10px;
    margin-left: -10px;
}
.row__sidebar .col-6,
.row__sidebar .col-12 {
    padding-left: 10px;
    padding-right: 10px;
}
.table_responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.owl_carousel .owl_item {
    transform: none;
    -webkit-transform: none;
}

.nav-link {
    transition: none;

    a {
        transition: none;
    }
}
.nav-tabs {
    border: none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border: 1px solid transparent;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: $primary_font_color;
    border-bottom: 2px solid $primary_font_color;

    @media screen and (max-width: 767px) {
        color: $primary_color;
        border-bottom: none;
    }
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border: 1px solid transparent;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: $primary_font_color;

    @media screen and (max-width: 767px) {
        color: $primary_color;
        border-bottom: none;
    }
    border-bottom: 2px solid $primary_font_color;
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1180px;
    }
}
@media screen and (min-width: 1440px) {
    .container {
        max-width: 1410px;
    }
}
// @media screen and (min-width: 1780px) {
//   .container {
//     max-width: 1690px;
//   }
// }

// @media screen and (min-width: 1920px) {
//   .container {
//     max-width: 1840px;
//   }
// }

// --------------------

// -------------------------

.dark_overlay {
    // overflow: hidden;
    // overflow-y: scroll;
    height: 100vh;
    // position: fixed;
    // header {
    //   position: fixed;
    //   height: 0;
    // }
    main {
        padding: 0;
    }
}

.dots_btn {
    position: absolute;
    width: 22px;
    height: 22px;
    display: block;
    right: 15px;
    top: 24px;
}
.dots_btn span {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    right: 0;
    width: 22px;
    height: 2px;
    background-color: #bdbdbd;
    border-radius: 2px;
    transition: 0.5s ease;
    transition-property: width, background-color;
}
.dots_btn span:first-child {
    top: 0;
}
.dots_btn span:nth-child(2) {
    top: 10px;
    width: 16px;
}
.dots_btn span:last-child {
    top: 20px;
    width: 10px;
}
.dots_btn:hover span {
    background-color: #6164ff;
}
.dots_btn--active span {
    background-color: #6164ff;
}
.dots_btn--active span:nth-child(2) {
    width: 22px;
}
.dots_btn--active span:last-child {
    width: 22px;
}
.label {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: $primary_font_color;
    margin: 0 0 12px 0;
    @media (max-width: 768px) {
        font-size: 18px;
    }
}
.primaryBtn {
    background: $primary_color;
    border-radius: 4px;
    font-family: $primary_font;
    color: #ffffff;
    padding: 8px 1.5rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5rem;
    font-style: normal;
    &:hover {
        color: $primary_color;
        border: 1px solid $border_color;
        background: transparent;
    }
}
.toolTipChanges {
    .tooltip {
        .arrow {
            &::before {
                border-right-color: #7557bb;
            }
        }
    }
}

.dropbtn {
    background-color: #4caf50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 0 !important;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    justify-content: center !important;
    width: 188px !important;
}

.dropdown-content a {
    padding: 26px 13px !important;
    text-decoration: none;
    display: block;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.02em;

    /* Grey / 1 */

    color: #333333;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}
.selectInput {
    .form-control {
        &:focus {
            border-color: #cccccc;
            box-shadow: none !important;
        }
    }
}
.detailsPageSliderCss {
    text-align: center;
    // @media screen and (max-width: 1024px) {
    // width: 80%;
    // }
    .swiper-container {
        max-width: 90%;
    }
    .swiper-slide {
        height: 150px;
        // margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: inherit;
        max-width: 120px !important;
        cursor: pointer;
        .NftTitle {
            // text-align: left;
            max-width: inherit;
            min-height: 60px;
            p {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.02em;
                color: #666666;
                word-break: break-word;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // overflow: hidden;
            }
        }
        // @media screen and (max-width: 1024px) {
        //   max-width: 90%;
        //   width: 30% !important;
        // }
        // @media screen and (max-width: 990px) {
        //   max-width: 90%;
        //   width: 250px !important;
        // }
        img {
            border-radius: 4px;
            width: inherit;
            max-width: 400px;
            object-fit: contain;
            height: inherit;
            max-height: 90px;
            // @media screen and (max-width: 1024px) {
            //   width: 80%;
            //   max-width: 80%;
            // }
        }
    }
    // @media (max-width: 768px) {
    //   .swiper-container {
    //     max-width: 80%;
    //   }
    //   .swiper-slide {
    //     // max-width: 80px;
    //     width: 348px !important;
    //     margin-right: 0 !important;
    //   }
    // }
    // @media (max-width: 361px) {
    //   .swiper-container {
    //     max-width: 80%;
    //   }
    //   .swiper-slide {
    //     width: unset;
    //   }
    // }
}

.EditNftModel {
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #222227;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #bdbdbd;
        outline: 1px solid #222227;
    }
    a {
        color: #7557bb;
    }
}
.topratedSwiper {
    .swiper-container {
        width: 100%;
    }
    .swiper-wrapper {
        width: 100%;
    }
    .swiper-slide {
        // width: 32% ;
        // width: 100%;
        // max-width: 33%;
        // margin: 0 8px 0 8px;
        // margin-left: 8px;
        // margin-right: 0px !important;
    }
}
.helpText {
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #666666;
}
// .galleryGrid {
//   display: grid;
//   grid-template-columns: [col1-start] 100px [col2-start] 100px [col3-start] 100px [col3-end] 100px 100px;
//   grid-template-rows: [row1-start] auto [row2-start] auto [row2-end];
//   grid-gap: 10px;
//   // background-color: #fff;
//   // color: #444;
//   > div {
//     // background-color: #444;
//     // color: #fff;
//     // border-radius: 5px;
//     // padding: 20px;
//     font-size: 150%;
//   }
//   .item1 {
//     grid-row: row1-start / row2-end;
//     grid-column: col1-start / col3-start;
//   }
// }
.NFTDetailsModel {
    padding: 0 !important;
    border: none;
    border-radius: 0 !important;
    .modal-dialog {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        margin: 0;
        .modal-content {
            border: none;
            border-radius: 0 !important;

            background: rgba(0, 0, 0, 0.58);
            // height: min(100vw - 50px, 100vh - 50px);
            // width: min(100vw - 50px, 100vh - 50px);
            background: transparent;
            border: 0px;
            position: initial;
            border-radius: initial;
            // max-height: calc(100vh - 32px);
            // max-width: calc(100% - 32px);
            flex-direction: column;
            display: flex;
            // align-items: center;
            width: fit-content;
            height: fit-content;
            margin: auto;
            @media only screen and (max-width: 768px) {
                // max-height: 100vh;
                // max-width: 100%;
                // height: 100vh;
            }
            .modal-header {
                border: none;
                padding: 0;
                padding-right: 10px;
                position: absolute;
                top: 1%;
                right: 1%;
                button {
                    color: #ffffff;
                    opacity: 1;
                }
            }
            .modal-body {
                // width: fit-content;
                // height: 100%;
                margin: auto;
                // height: min(100vw - 50px, 100vh - 50px);
                // width: min(100vw - 50px, 100vh - 50px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @media only screen and (max-width: 768px) {
                    padding: 0px;
                }
                .mainContainer {
                    .Imagecontainer {
                        -webkit-box-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        justify-content: center;
                        flex-direction: column;
                        display: flex;
                        height: 100%;
                        min-height: inherit;
                        width: 100%;
                        position: relative;
                        border-radius: inherit;
                        .imagecontainerDilog {
                            -webkit-box-align: center;
                            align-items: center;
                            display: flex;
                            -webkit-box-pack: center;
                            justify-content: center;
                            max-height: 100%;
                            max-width: 100%;
                            overflow: hidden;
                            position: relative;
                            height: 100%;
                            width: 100%;
                            img {
                                object-fit: contain !important;
                                width: auto;
                                height: auto;
                                min-height: min(100vw - 50vw, 100vh - 50vh);
                                min-width: min(100vw - 50vw, 100vh - 50vh);
                                max-height: min(100vw - 5vw, 100vh - 5vh);
                                max-width: min(100vw - 5vw, 100vh - 5vh);
                            }
                        }
                    }
                }
            }
        }
    }
}
.demoClass {
    width: 500px !important;
    max-width: 500px !important;
}

.Categories > div > div > button {
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: none;

    @media screen and (max-width: 767px) {
        width: 100%;
        border: none;
    }
}

.Categories > div > div > button.css-48ayfv {
    border: 1px solid #7557bb;
    width: 100%;

    @media screen and (max-width: 767px) {
        border: none;
    }
}

.multibox {
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.21);
    border-radius: 7px;
    padding-top: 1px;
}

.Categories {
    @media screen and (max-width: 767px) {
        > div > div > button > span > svg {
            // font-family: Jost !important;
            // font-weight: 600 !important;
            // font-size: 20px !important;
            // line-height: 1.5rem !important;
            // letter-spacing: 0.02em !important;
            height: 38px !important;
            // width: 90% !important;
            color: #9a9a9a !important;
            // padding-right: 0px;
            // padding-left: 33px;
            // margin-left: 13px;
        }
    }
}
